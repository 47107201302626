@font-face {
  font-family: Aspergit;
  font-weight: bold;
  font-style: italic;
  src: url(./fonts/aspergit.bold-italic.otf) format("opentype");
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Aspergit, sans-serif;
}

body {
  background: url(./img/background.png) no-repeat center;
  background-size: cover;
}

#id_basic {
  position: absolute;
  text-align: center;
  top: 29%;
  left: 0;
  right: 0;
  margin: auto;
  animation: 1s ease-out 0s 1 appear;
}

#id_name {
  font-size: 8vh;
  text-transform: uppercase;
}

#id_profession {
  font-size: 5vh;
  margin-top: 3.5rem;
}

#id_email {
  font-size: 2.5vh;
  color: rgb(245, 127, 32);
  position: absolute;
  left: 12%;
  bottom: 0;
  margin: 1rem;
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

#id_icons {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  animation: 1s ease-out 0s 1 slideInFromRight;
}

#id_icons a {
  display: block;
  margin-top: 2vh;
  text-decoration: none;
}

#id_icons img {
  height: 5vh;
}

.menu {
  background-color: #5A7F76;
}

@media screen and (max-width: 1400px){
  #id_email {
    left: 7%;
  }
}

@media screen and (max-width: 1080px){
  #id_email {
    left: 3%;
  }
}

@media screen and (max-width: 840px){
  #id_email {
    left: 0;
  }
}

@media screen and (max-width: 460px){
  #id_icons img {
    height: 4vh;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
